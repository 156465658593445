import { ComponentType } from '@angular/cdk/portal';
import {
    AsyncValidatorFn,
    FormArray,
    FormControl,
    FormGroup,
    ValidatorFn,
} from '@angular/forms';
import { IAgent } from '@src/app/Agency/agency.shared.interfaces';

import { TemplateRef } from '@angular/core';
import { IPensionPlan } from '@src/app/Employee/Pension Module/models/pensiondata.model';
import { Moment } from 'moment';

import { HttpContext, HttpHeaders, HttpParams } from '@angular/common/http';
import { SafeResourceUrl } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';
import {
    EAttendanceOptions,
    EB360Bucket,
    EB360FormFieldsTypes,
    EB360Lang,
    EContentType,
    EDocumentType,
    EEmployeeModule,
    EEmployeeProcess,
    EEmployeeState,
    EEmployeeType,
    EFlow,
    EIDType,
    ELanguage,
    EModule,
    EPermission,
    EPesnionTypes,
    EPrivilege,
    EProcessType,
    ERecipientType,
    ETableTypes,
    ETaskTypes,
    MessageType,
    NotificationMehods,
    Role,
    State,
    StatusHeb,
    SuprviseOn,
    TableActions,
    TableEvents,
} from 'src/app/shared/helpers/shared.enums';
import {
    EBinaryChoice,
    EF101HealthCareProvider,
    EF101Kibbutz,
} from '../enums/form-101-enum';
import {
    IF101ChildrenFromAPI,
    IF101SpouseS1,
} from '../interfaces/Form101/f101.shared.interface';
import { TLabelValue } from './types';

export interface IMessageDetails {
    fullnames: string[];
    participants: string[];
    firstNames: string[];
    lastNames: string[];
    initials: string[];
    _id: string;
    messageId: string;
    color: string;
    position;
    unread?: number;
    employeeNumber: number;
    status: ChatStatus;
    messages?: IChatBody[];
    lastMessages?: IChatBody[];
    chatType: string;
    groupImg?: string;
    groupName?: string;
}

export interface ILastMessages {
    createdAt: string;
    isRead: boolean;
    lastMsg: string;
    participants: string[];
    _id: string;
    unread: number;
    sender: string;
    sendAt: string;
    chatType: string;
    groupImg?: string;
    groupName?: string;
}

export enum ChatStatus {
    OPEN = 'OPEN',
    CLOSED = 'CLOSED',
    MINIMIZED = 'MINIMIZED',
}

export interface INewBranch {
    bank: {
        bankCode: string;
        bankName: string;
    };
    branchNumber: string;
    branchName: string;
}

export interface MongoDocumentBase<T> {
    _id: string;
    createdAt: string;
    updatedAt: string;
    __v: number;
}

export interface IEmployeeFieldMapping {
    // "fieldNameOnData" is the key in the input data that holds an employee _id
    fieldNameOnData: string;
    // "populateAs" is the property name you want to store the found employee object in
    populateAs: string;
}

export interface ILoadingContext {
    track: boolean;
    fnName?: string;
}

export interface B360HttpOptions {
    headers?:
        | HttpHeaders
        | {
              [header: string]: string | string[];
          };
    context?: HttpContext;
    observe?: any;
    params?: {
        [param: string]: any;
    };
    reportProgress?: boolean;
    responseType?: any;
    withCredentials?: boolean;
    transferCache?:
        | {
              includeHeaders?: string[];
          }
        | boolean;
    [key: string]: any;
}

export interface IStateHistory {
    state: State;
    at: IB360DateObject;
    by: string;
    _id: string;
    createdAt: string;
    updatedAt: string;
    comment?: string;
}

export interface IB360Attachment {
    type: EContentType;
    url: string;
    name: string;
}

export interface IUploadedAttachment {
    type: EContentType;
    url: string;
    name: string;
}
export interface IF101Attachment {
    file: IUploadedAttachment;
    idx: number;
}

export interface IEmployeeOnboardingForm {
    welcomeSlideForm: Partial<{ language: IB360Language }>;
    personalDetailsSlideForm: Partial<any>;
    addressSlideForm: Partial<any>;
    bankSlideForm?: Partial<any>;
    avatarSlideForm: Partial<any>;
}

export interface IPayslipValidationResult {
    detectedMonth: string;
    detectedYear: string;
    bizHasPayslipsForDate: boolean;
}

export interface IDualSelect {
    filterBy: string[];
    selectedOptions: any[];
}

export interface IF101ChangesFromApi {
    taxYear: string;
    current: string | number | boolean;
    previous: string | number | boolean;
    name: string;
    key: string;
}

export interface IAwsSignedUrlFromApi {
    url: string;
    mime: string;
    fileName: string;
    key?: string;
}

export interface IHttpOptions {
    headers?:
        | HttpHeaders
        | {
              [header: string]: string | string[];
          };
    context?: HttpContext;
    observe?: 'body';
    params?:
        | HttpParams
        | {
              [param: string]:
                  | string
                  | number
                  | boolean
                  | ReadonlyArray<string | number | boolean>;
          };
    reportProgress?: boolean;
    withCredentials?: boolean;
    [key: string]: any;
}

export interface IB360ImageFile {
    url: string | SafeResourceUrl;
    mime: string;
    fileName: string;
}

interface SignaturePadFormControls {
    signature: FormControl<string>;
    signatureDate: FormControl<Moment>;
}

export type SignaturePadForm = FormGroup<SignaturePadFormControls>;

type FormControlValueType<T> = T extends FormControl<infer U> ? U : never;
export type FormValue<T> = {
    [P in keyof T]: T[P] extends FormControl<any>
        ? FormControlValueType<T[P]>
        : T[P] extends FormGroup<infer U>
        ? FormValue<U>
        : never;
};

export interface IBizPayslipRow {
    _id: string;
    idNumber: string;
    employee?: string;
    business: string;
    employeeNumber: string;
    employeeName: string;
    date: string;
    pageNumber: number[];
    pdfLink: string;
    status: State;
    total?: string;
    broto?: string;
    taxes?: string;
    workdays?: string;
    pension?: string;
    vacation?: string;
    hidden: boolean;
    createdAt: Date | number;
    month: string;
    year: string;
    salaryType?: string;
    viewedAt: Date | number;
    stateHistory: IStateHistory[];
}
export interface IHRCycleSettingsFromApi {
    readonly _id: string;
    readonly createdAt: string;
    readonly createdBy: string;
    readonly deactivatedAt: string;
    readonly deactivatedBy: string;
    employee: string;
    filters: Array<any>;
    /**
     * @description text array that
     * describes the flow of the process say
     * f101 -> payslip -> pension
     */
    flow: String[];
    /**
     * @description a long string that
     * describes the flow of the process in one string
     * for tooltip purposes
     */
    flowTooltip: String;

    isActive: boolean;
    priority: number;
    processName: string;
    processNumber: number;
    processType: EProcessType;
    recipientType: ERecipientType;
    recipients: Array<any>;

    /**
     * @description the greeting text
     * that an employee will see when he
     * opens the process for the first time
     */
    welcomeText: string;
}

export type TChanges = { key: string; newValue: string; oldValue: string };

export interface IEmployeeChangesLogFromApi {
    _id: string;
    employee: string;
    business: string;
    organization: string;
    depratment: string;
    factory: string;
    createdAt: string;
    updatedAt: string;
    approvedAt: string;

    whoMadeTheChange: string;
    whatChanged: TChanges;
    needApproval: boolean;
    hidden: boolean;
    whyChanged: string | null;
    by?: IEmployee;

    /**
     * @description does not exist yet
     * in the future consider adding types for different employee change logs
     * e.g: change in personal details, change in bank details etc
     */
    changeType?: string;
}

export interface IAttachmentFromApi {
    /**
     * @description the description
     * of what the attachment is writter by
     * the employee
     */
    readonly employeeDescription: string;

    /**
     * @description the last emp._id
     * that updated the attachment
     */
    readonly updatedBy: string;

    readonly _id: string;
    awsLink: string;
    business: string;
    createdAt: string;
    department: string;
    employee: string;
    isActive: boolean;
    organization: string;
    originalFileName: string;
    state: State;
    updatedAt: string;
}

export interface IPensionTableFromApi {
    _id: string;
    state: State;
    employee: string;
    pdfLinkAWS?: string;
    pensionProof?: string;
    plan: {
        code: string;
        shortName: string;
        type: EPesnionTypes;
    };
    agent: Partial<IAgent> | string;
    business: string;
    depositStartDate: string;
    finalApproveDate: string;
    agentDetils?: IAgent;
}

export interface IPensionTableRow
    extends Omit<IPensionTableFromApi, 'employee'> {
    employee: IEmployee;
}

export interface IDepartmentForm {
    _id: FormControl<string>;
    name: FormControl<string>;
    number: FormControl<string>;
    employees: FormControl<number>;
    customFieldsValues: FormGroup<{
        [key: string]: FormControl<any>;
    }>;
}

export interface IFactoryForm {
    _id: FormControl<string>;
    name: FormControl<string>;
    number: FormControl<string>;
    customFieldsValues: FormGroup<{
        [key: string]: FormControl<any>;
    }>;
}

export interface IDepartmentFactoryForm {
    department: FormGroup<IDepartmentForm>;
    factory: FormGroup<IFactoryForm>;
}

export interface IKitForm {
    kitNumber: FormControl<string>;
    kitName: FormControl<string>;
    docs: FormArray<
        FormGroup<{
            _id: FormControl<string>;
            value: FormControl<string>;
            isOptional: FormControl<boolean>;
            label: FormControl<string>;
        }>
    >;
}

export interface IB360Language {
    text: string;
    type: EB360Lang;
    dir: 'rtl' | 'ltr';
}

export type TTableMap = {
    field: string;
    header: string;
    type: any;
    class: string;
};

export enum ETableMapFieldType {
    OTHER = 'other',
    HANDELED_BY_MANAGER = 'handeledByManager',
    EMP_DEPARTMENT = 'empDepartment',
    EMP_IMAGE = 'employeeImg',
    AVATAR = 'avatar',
    EMP_STATE = 'empState',
    EMP_CONNECTION_DATA = 'empConnectionData',
    EMP_EXTRA_DATA = 'empExtraData',
    EMP_COMPANY = 'empCompany',
    EMP_DATES = 'empDates',
    EMP_OPEN_TASKS = 'empOpenTasks',
    EMP_TYPE_AND_BIRTHDATE = 'empTypeAndBirthdate',
    EMP_FORM101_DATA = 'form101Data',
    EMP_PAYSLIP_DATA = 'payslipData',
    EMP_PROCESS_DATA = 'processData',
    EMP_PENSION_DATA = 'pensionData',
    EMP_DETAILS = 'employeeDetails',
    TEXT = 'text',
    DATE = 'date',
    ACTION_BTNS = 'actions',
    BUSINESS_IMG = 'businessImg',
    IMG = 'img',
    MODULE_PERMISSION = 'modulePermission',
    ERROR = 'error',
    TO_EMPLOYEE_SHEET = 'toEmployeeSheet',
    TOGGLE = 'toggle',
    CHANGEABLE_STATE = 'changeableState',
    JOIN_FIELDS_ONE_COLUMN = 'joinFieldsOneColumn',
    LAST_UPDATE = 'lastUpdate',
    SELECT = 'select',
    ACTIONS = 'actions',
}

export type TActionBtns = {
    icon: string;
    eventType: TableEvents;
    class: string;
    color?: string;
    tooltip?: string;
};

export interface DialogData<T = undefined> {
    headerText: string;
    icon: string;
    template: TemplateRef<any>;
    iconColor?: string;
    context?: T;
}

export interface IUserPrefFromLocalDb {
    _id: string;
    data: {
        dir: 'rtl' | 'ltr';
        locale: string;
        preferedLang: EB360Lang;
        theme: {
            mode: 'light_mode' | 'dark_mode';
            primaryColor: string;
        };
    };
}

export interface IApproveOrSendToNextRecipient {
    vals: any;
    signature: string;
    form: INextDoc;
    nextRecipientNumber: number;
}

export interface IRepairToApi {
    formId: string;
    message: string;
    fixIdAppend: boolean;
}

export interface IF101RepairForm {
    id: string;
    generalMessage: string;
    selectedAppends: string[];
}

export interface IUserAvatar {
    idNumber: string;
    url: string;
}

export interface IF101TableDataFromApi {
    readonly _id: string;
    business: string;
    changes: string[];
    department: string;
    employee: string;
    factory: string;
    isActive: boolean;
    isFixId: boolean;

    organization: string;
    sendAt: string;
    sortIndex: number;
    state: State;
    taxYear: string;
    transmitted: boolean;
    message?: string;

    pdfLinkAWS?: string;
    approvedBy?: string;
    finalApproveDate?: Date;
    deActivatedBy?: string;
    deActivatedAt?: string;
    bankDetails?: IBankDetails;
    b?: IBankDetails;

    lastUpdateBy?: string;
    lastUpdateAt?: string;
}

export interface B360IDControlValue {
    idNumber: string;
    country: {
        countryEn: string;
        countryHeb: string;
        iso2: string;
        iso3: string;
        phoneCode: string;
    };
}

export interface ILoginCredsToApi {
    idNumber: {
        idNumber: string;
        country: {
            countryEn: string;
            countryHeb: string;
            iso2: string;
            iso3: string;
            phoneCode: string;
        };
    };
    connectionMethod: {
        methodValue: string;
        method: 'phone' | 'email';
    };
    recaptchaToken: string;
}

export interface IRecaptchaSate {
    isErr: boolean;
    token: string | null;
}

export interface IAgencyModules {
    form101: boolean;
    attendance: boolean;
    benefits: boolean;
    allowRouteToEmployeeSide: boolean;
    businessTree: boolean;
    chat: boolean;
    digitalArchive: boolean;
    documents: boolean;
    paySlips: boolean;
    pension: boolean;
    pensionClearing: boolean;
    phoneBook: boolean;
    social: boolean;
    timewatch: boolean;
    allowRouteToEmployerSide: boolean;
}
export interface IAgencyForApi {
    img: string;
    agencyName: string;
    agencyNumber: string;
    deduction: string;
    address: IAddress | null;
    houseNumber: string;
    zipCode: string;
    phoneNumber: string;
    modules: Partial<{
        canGiveModules: Partial<IAgencyModules>;
        canWatchModules: Partial<IAgencyModules>;
    }>;
    pensionCompanies: Array<string>;
    agencyManager: Partial<{
        firstName: string;
        lastName: string;
        idNumber: string;
        email: string;
        phoneNumber: string;
    }>;
}

export interface IPensionCompany {
    _id?: string;
    companyId: string;
    companyName: string;
    email: string;
    isActive: boolean;
    phoneNumber: string;
    website: string;
}

export interface IB360Filter {
    name: string;
    selected: number;
    total: number;
    filterByFields: string[];
    searchTerms: string[];
}

export interface IB360FilterableField {
    key: string;
    fields: string[];

    /**
     * @description holds a value to let pipe know
     * if the field is a date field or other transform is needed
     * @default text
     */
    mapAs?: 'translate' | 'date';
}

export type AnyKeys<T> = { [P in keyof T]?: T[P] | any };
export interface AnyObject {
    [k: string]: any;
}

export type B360Patch<T> = {
    filter: AnyKeys<T>;
    update: AnyKeys<T>;
};

export type B360BulkOp<T> = {};

export type B360Update<T> = {
    [P in keyof T]?: T[P] | any;
};

export interface IEntityOrgTableRow {
    aparatmentNumber: string;
    city: string;
    companyName: string;
    companyNumber: string;
    deduction: string;
    houseNumber: string;
    img: string;
    isActive: boolean;
    modules: IModuleSchema;
    organizationManager: IUser;
    phoneNumber: string;
    street: string;
    totalBusinesses: number;
    totalEmployees: number;
    zipCode: string;
    _id: string;
}
export interface IEntityAgencyTableRow {
    address: IAddress;

    agencyName: string;
    agencyNumber: string;
    deduction: string;
    houseNumber: string;
    img: string;
    isActive: boolean;

    agencyOwner: IUser;
    ownerId: string;

    modules: Partial<{
        canGiveModules: Partial<IAgencyModules>;
        canWatchModules: Partial<IAgencyModules>;
        _id: string;
    }>;
    phoneNumber: string;
    organization: Array<IOrganization | string>;
    businesses: Array<IBusiness | string>;
    pensionCompanies: Array<IPensionCompany | string>;
    totalOrganization: number;
    totalEmployees: number;
    zipCode: string;
    _id: string;
}

export interface IPayslipCardData {
    _id: string;
    month: string;
    date: string;
    year?: string;
    type: 'payslip' | 'form106';
    fullDate: string;
    link: string;
    total?: string;
    broto?: string;
    taxes?: string;
    vacation?: string;
    workdays?: string;
    pension?: string;
    totalTaxes?: string;
    totalSalary?: string;
    totalPension?: string;
    totalWorkMonths?: string;
    status: State;
}

export interface IEmployeePayslipsFromApi {
    _id: number;
    docs: Array<IPayslipCardData>;
}

export interface IPdfDocDataFromApi {
    dbdata: FormField;
    buffer: Uint8Array;
    isOptional: boolean;
}

export interface Ikit {
    kitNumber: string;
    kitName: string;
    docs: Array<{ label: string; value: string }>;
}

export interface IEmployeeDocDataObjFromApi {
    _id: string;
    name: string;
    state: State;
    createdAt: string;
    awsLink?: string;
    formId: string;
    isMine?: boolean;
    rejectReason?: string;
}
export interface IEmployeeDigitalFormsFromApi {
    openForms: Array<IEmployeeDocDataObjFromApi>;
    doneForms: Array<IEmployeeDocDataObjFromApi>;
    activeAttachments: Array<IEmployeeDocDataObjFromApi>;
    rejectedAttachments: Array<IEmployeeDocDataObjFromApi>;
}

export interface FormField {
    generatedOnPageNum: number;
    x: number;
    y: number;
    width: number;
    height: number;
    fieldBackgroundColor: string;
    fieldType?: FieldType;
    resized?: boolean;
    moved?: boolean;
    inputType?: string;
    inputLabel?: string;
    fieldId?: string;
    inputId?: string;
    label?: string;
    selectOptions?: Array<string>;
    value?: any;
    groupLabel?: string;
    validations?: Array<any>;
    color?: any;
    isCombed?: boolean;
    id?: string;
    itemLocked?: boolean;
    style?: string | { width: number; height: number; transform: string };
    required?: boolean;
    groupId?: string;
    totalPages?: number;
    attachToSignee?: string;
    isOptional?: boolean;

    _id?: string;
}
export interface ISignatureOptions {
    id: string;
    value: string;
    viewValue: string;
}

export interface ISignatureForm {
    signeeLabel: string;
    generateSignatureOnPage: Array<string>;
    signeeColor: string;
    signeeId: string;
}

export interface FormDetails {
    file: string | Uint8Array;
    base64File: string;
    for: Array<any>;
    forType: string;
    formName: string;
    formNumber: string;
    textColor: { r: number; g: number; b: number };
    fields: Array<Array<FormField>>;
    pdfSize: { width: number; height: number };
    formSignees: Array<ISignatureForm>;
    editMode?: boolean;
    formId?: string;
}

export interface ITemplateRowFromApi {
    _id: string;
    business: string;
    createdAt: string;
    createdBy: string;
    formNumber: string;
    formName: string;
    formSignees: Array<ISignatureForm>;
    relatedTo: string;
    isActive: boolean;
    pdfLinkAWS: string;
}

export interface DigitalFormSubmit {
    formTemplateId: string;
    data: { fieldId: string; value: any }[];
}

export enum FieldType {
    AUTOCOMPLETE,
    MANUAL,
}

export interface IUserLeanBusinesses {
    _id: string;
    name: string;
    number: string;
    isActive: boolean;
    img: string;
}

export interface ITimeoffToApi {
    absenceType: EAttendanceOptions;
    employee?: string;
    diff: number;
    remark: string;
    start: string;
    end: string;
    fileName?: string;
}

export interface IReportNewAbsenceForm {
    range: FormGroup<{
        start: FormControl<Moment | null>;
        end: FormControl<Moment | null>;
    }>;
    absenceType: FormControl<any>;
    remark: FormControl<string>;
    append: FormControl<File>;
}

export interface ITimeWatchEditsV2 extends IShiftDetails {
    _id?: any;
    editedBy: IEmployee;
    editedAt: Date | number | string;
    remark?: string;
}
export enum ETimeWathcEmployeeState {
    IN_SHIFT = 'in_shift',
    ON_A_BREAK = 'on_a_break',
    IDLE = 'idle',
    DONE_FOR_THE_DAY = 'done',
    NOT_COMING = 'not_coming',
}

export enum ETimeWathcState {
    EMPLOYER_PENDING = 'EMPLOYER_PENDING',
    EMPLOYEE_PENDING = 'EMPLOYEE_PENDING',
    APPROVED = 'APPROVED',
    UPDATED_IN_SALARY = 'UPDATED_IN_SALARY',
    IDLE = 'IDLE',
    REJECTED = 'EMPLOYER_REJECTED',
}

export interface ITimeWatch {
    _id?: string;
    business: IBusiness;
    organization: IOrganization;
    department: IDepartment;
    factory: IFactory;
    employee: IEmployee;
    project: ITimeWatchProject;

    shiftType?: EAttendanceOptions;
    startShiftDetails?: IShiftDetails;
    endShiftDetails?: IShiftDetails;
    isActive?: boolean;
    readOnly?: boolean;

    shiftStatus?: ETimeWathcEmployeeState;
    recordState?: ETimeWathcState;

    employeeRemark?: string;
    awsAppendLink?: string;
    editsHistory?: ITimeWatchEditsV2[];

    // for filtering purposes
    shiftStartDay: string;
    shiftStartMonth: string;
    shiftStartYear: string;
    shiftDayInWeek: number;
    shiftDate: Date;

    shiftGroupId?: string;
    groupIdTransmitAsOneShift?: boolean;

    updatedAt?: Date | number;
    updatedBy?: IEmployee | string;
    approvedAt?: Date | number;
    approvedBy?: IEmployee | string;
    transmitted?: boolean;
    transmittedFileName?: string;
}

export interface IShiftDetails {
    _id?: any;
    time: string;
    date: string;
    address?: string;
    lat?: number;
    lng?: number;
    insertedManually?: boolean;
    createdBy?: string;
}

export interface ILoaction {
    description: string;
    address: string;
    geoFenceInMeters: number;
    icon: string;
    lat: number;
    lng: number;
    photo: string;
}

export interface ICheckboxWithValue<T> {
    checkboxState: boolean;
    selectedValue: T;
}
export interface ILabelValue<T, U> {
    label: T;
    value: U;
}

export interface IProcessAction {
    type: EEmployeeProcess;
    label: string;
    value?: {
        _id: string;
        name: string;
    };
    _id?: string;
}

export interface IOvertime {
    numberOfHours: number;
    rateInPercent: number;
}
export interface IWorkdays {
    sunday: boolean;
    monday: boolean;
    tuesday: boolean;
    wednesday: boolean;
    thursday: boolean;
    friday: boolean;
    saturday: boolean;
}

export interface ITimeWatchProjectLocationSettings {
    isLocationRequired: boolean;
    allowedLocations: ILoaction[];
}

export interface ITimeWatchProjectRemaindersSettings {
    autoClockOutAfter: ICheckboxWithValue<ILabelValue<string, number>>;
    clockInReminder: ICheckboxWithValue<ILabelValue<string, string>>;
    clockOutReminder: ICheckboxWithValue<ILabelValue<string, string>>;
    overtimeLimitReminder: ICheckboxWithValue<ILabelValue<string, number>>;
    workDays: IWorkdays;
}

export interface ICustomField {
    _id?: any;
    code: string;
    forType: string;
    description: string;
    type: string;
    validations?: any[];
    isActive?: boolean;
    createdAt?: any;
    options?: ICustomFieldOption[] | { _id: any };
    required?: boolean;
}

export interface ICustomFieldFromApi {
    _id: string;
    businessId: string;
    fields: ICustomField[];
}

export enum EAudieneceType {
    BUSINESS = 'BUSINESS',
    FACTORY = 'FACTORY',
    DEPARTMENT = 'DEPARTMENT',
}

export interface ITimeWatchProjectGeneralSettings {
    projectName: string;
    projectNumber: string;
    audience: {
        audienceType: ILabelValue<EAudieneceType, string>;
        selectedAudience: Array<ILabelValue<string, string>>;
    };
    audienceFilters: Array<{
        filter: Partial<ICustomField>;
        selectedValues: Array<ICustomFieldOption>;
    }>;
}

export interface ITimeWatchProjectOvertimeSettings {
    breakDeduction: ICheckboxWithValue<ILabelValue<number, number>>;
    breakDeductionRestriction: ICheckboxWithValue<ILabelValue<number, number>>;
    overtimeCalc: Array<IOvertime>;
}

export interface ITimeWatchProject {
    _id?: string;
    organization?: IOrganization | string;
    business?: IBusiness | string;
    isActive?: boolean;
    createdAt?: Date | number;
    updatedAt?: Date | number;
    deletedAt?: Date | number;
    createdBy?: IEmployee | string;
    updatedBy?: IEmployee | string;
    deletedBy?: IEmployee | string;

    general: ITimeWatchProjectGeneralSettings;
    locations: ITimeWatchProjectLocationSettings;
    remainders: ITimeWatchProjectRemaindersSettings;
    overtime: ITimeWatchProjectOvertimeSettings;
}

export interface IB360LocationCenter {
    lat: number;
    lng: number;
}

export interface IB360CheckboxWithSelectControls {
    checkboxState: FormControl<boolean>;
    selectedValue: FormControl<TLabelValue>;
}

export type IB360GroupedInputs = Array<{
    title?: string;
    description?: string;
    inputs: IB360FormField[];
}>;

export const DEFAULT_SNACKBAR: IB360SnackbarData = {
    message: '',
    class: 'info',
};

export interface IB360FilterData {
    title: string;
    data: TLabelValue[];
    type: string;
}

export interface IB360Tab {
    tabIcon: string;
    tabLabel: string;
    component: ComponentType<unknown>;
    componentData?: any;
    actionMenu?: NavItem[];
    activeTab?: boolean;
    needAccessPermission?: boolean;
    permissionTo?: EModule;
    count$?: BehaviorSubject<number>;
}

export interface IB360ConfirmDialogData {
    title: string;
    titleIcon?: string;

    /**
     * @description if true will require text authorization
     */
    needTextApproval?: boolean;

    /**
     * @description if true will show textarea for user to write
     * a comment
     */
    withComment?: boolean;

    /**
     * @description label for the comment textarea field
     */
    commentLabel?: string;

    /**
     * @description and array of custom text to show on dialog
     *
     */
    textList?: string[];

    message: string;
    buttons: Array<{
        text: string;
        color: 'primary' | 'accent' | 'warn';
        data: any;
    }>;
}
export const DEFAULT_SNACKBAR_ERROR: IB360SnackbarData = {
    message: 'אופס, קרתה שגיאה',
    class: 'error',
    icon: 'error',
    iconType: 'mat',
    iconColor: 'warn',
};
export const DEFAULT_SNACKBAR_SUCCESS: IB360SnackbarData = {
    message: 'snackbar.success',
    class: 'snackSuccess',
    icon: 'check_circle',
    iconType: 'mat',
    iconColor: 'primary',
};

export interface IB360LottieData {
    lottieOptions?: any;
    text?: string;
    width?: number | string;
    height?: number | string;
}

export interface IB360UploadFileSnackData extends IB360SnackbarData {
    fileName: string;
}
export interface IB360SnackbarData {
    message: string;
    action?: string;

    class: 'snackSuccess' | 'error' | 'warning' | 'info';
    icon?: string;
    iconType?: 'svg' | 'mat';
    iconColor?: 'primary' | 'accent' | 'warn';
}

export interface IB360ActionCardDialogData extends DialogData {
    cardType: 'discard';
}

export interface IB360PersignedUrlRequest {
    file: string;
    bucket?: EB360Bucket;
    returnBinary?: boolean;

    // in order to return needed keys back with url form backend
    [key: string]: any;
}

export interface IB360PresignedUploadUrlRequest {
    fileName: string;

    bucket?: EB360Bucket;
}

export enum EAllowedActions {
    VIEW = 'view',
    EDIT = 'edit',
    DELETE = 'delete',
    UPDATE = 'update',
}
export interface IDigitalArchivePermittedUser {
    user: string | IEmployee;
    allowedActions: EAllowedActions[];
}

export interface IDigitalArchiveNote {
    note: string;
    date: Date | number;
    by: string | IEmployee;
}

export interface IB360NodeAutocompleteOption {
    label: string;
    path?: string;
    icon: string;
    type: string;
    updatedAt?: string | number | Date;
    rootFolderId: string;
    currentNodeId: string;
    containerFolderId: string;
    root: boolean;
    breadCrumbs: IB360EmployeeProfileDocumentsTableItem[];
}

export interface IFillerFormData {
    _id?: any;

    fillerId: any;
    fillerName: string;
    data: { [key: string]: any };
    signature: string;
    signedAt: Date | number;
    signeeId: string;
    fillerState: State;
    lastUpdate: Date | number;
    appends?: any;
    repairMsg?: string;
}

export interface INextDoc {
    _id: string;
    business: string | IBusiness;
    createdAt: string;
    currentFillerId: string;
    currentFillerIndex: number;
    currentFillers: Array<{ id: string; name: string }>;
    department: string | IDepartment;
    factory: string | IFactory;
    organization: string | IOrganization;
    formTemplate: string;
    document: string;
    employeeId?: string;
    isActive: boolean;
    isOptional: boolean;
    state: State;
    totalDocFillers: number;
    updatedAt: Date | number;
    docData?: Array<IFillerFormData>;

    kit?: any;
    log?: any;

    pdfLinkAWS?: string;

    approvedAt?: Date | number;
}

export interface IB360EmployeeProfileDocumentsTableItem {
    _id: string;
    employee?: string | IEmployee;
    business?: string | IBusiness;
    orangiztion?: string | IOrganization;
    icon: string;
    color: string;
    name: string;
    type: EDocumentType;
    system?: boolean;
    bucket?: EB360Bucket;
    createdAt?: string | number | Date;
    createdBy?: string | IEmployee;
    updatedAt?: string | Date | number;
    updatedBy?: string | IEmployee;
    createdByName?: string;
    approvedAt?: string | number | Date;
    approvedBy?: string | IEmployee;
    permittedUsers?: IDigitalArchivePermittedUser[];
    visibleToEmployee?: boolean;
    readOnly?: { value: boolean; password?: string };
    protected?: { value: boolean; password?: string };
    docsQtyInFolder?: number;
    state?: State;
    allowedActions?: EAllowedActions[];
    link?: string;
    tag?: string;
    tagColor?: string;
    notes?: IDigitalArchiveNote[];
    children: IB360EmployeeProfileDocumentsTableItem[];
}

export type TCustomFieldFromApi = ICustomFieldFromApi & ISelectedCustomField;
export interface ICustomFieldFromApi {
    _id: string;
    code: string;
    description: string;
    forType: ECustomFieldForType;
    isActive?: boolean;
    type: EB360FormFieldsTypes;
    validations?: any[];
    createdAt?: string;
    options?: ICustomFieldOption[];
    required?: boolean;
}
export interface ICustomFieldOption {
    _id?: string;
    code: string;
    value: string;
    label: string;
    createdAt: string;
    isActive: boolean;
    isDeletable?: boolean;
}

export enum ECustomFieldForType {
    EMPLOYEE = 'EMPLOYEE',
    BUSINESS = 'BUSINESS',
    FACTORY = 'FACTORY',
    DEPARTMENT = 'DEPARTMENT',
}

export interface IEmployeeProfileDetailsFromApi {
    _id: string;
    personalInfo: Array<TKeyValue>;
    employmentInfo: Array<TKeyValue>;
    customFieldsInfo: Array<TKeyValue>;
    customFieldsInputs: Array<any>;
}
export interface IEmployeePersonalDetailsFromApi {
    _id: string;
    addressInfo: Array<TKeyValue>;
    bankInfo: Array<TKeyValue>;
    spouseInfo: Array<TKeyValue>;
    kidsInfo: Array<IF101ChildrenFromAPI>;
}

export type TKeyValue = {
    key: string;
    value: string;
    field: string;
    optionId: string;
    type?: string;
};

export interface IUploadedEmployeesFromApi {
    employees: any[];
    createdBy: string | IEmployee;
    business: string | IBusiness;
    organization: string | IOrganization;
    createdAt: Date | number;
    sendSmsOrMailToEmps: boolean;
    fileType: string;

    status: State;
    isActive: boolean;

    erroredEmployees?: IUploadedEmployeeFromExcel[];
    updatedEmployees: IUploadedEmployeeFromExcel[];
    createdEmployees: IUploadedEmployeeFromExcel[];
    unChangedEmployees: IUploadedEmployeeFromExcel[];

    createdEmployeesCount?: number;
    updatedEmployeesCount?: number;
    erroredEmployeesCount?: number;
    unChangedEmployeesCount?: number;

    totalEmployees: number;
    generalError: any;
    visable: boolean;
    _id: string;
}

export interface IUploadedEmployeesError {
    description: string;
    key: string;
    result: any;
    value: any;
}

export interface IUploadedEmployeeFromExcel {
    idNumber: number;
    country?: string;
    firstName: string;
    lastName: string;
    email: string;
    errors?: IUploadedEmployeesError[];
    phoneNumber: number;
    factory?: number;
    department?: number;
    employeeNumber?: number;
    manager?: number;
    Biskits01?: number;
    startDate: Date;
    employementEndDate?: Date;
}

export interface FolderNode {
    folderName: string;
    [key: string]: any;
    children?: FolderNode[];
}

export const fieldsForRename = {
    name: 'שם עובד',
    employeeNumber: 'מספר עובד',
    idNumber: 'תעודת זהות',
};

export interface TableActionsBtns {
    class?: string;
    icon?: string;
    color?: string;
    text?: string;
    eventType: TableEvents;
    tooltipText?: string;
}

export interface ICreateEmployeeFromExcelDialogData {
    isForeign: boolean;
    selectedFile: File;
    xlsxHeaders: string[];
    employeeTypeString: string;
    filePath: string;
    employees?: IEmployee[];
    title: string;
}

export interface IB360FormField {
    id: string;
    type: EB360FormFieldsTypes;
    class?: string;
    req?: boolean;
    label?: string;
    validators?: ValidatorFn[];
    asyncValidators?: AsyncValidatorFn[];
    placeholder?: string;
    displayField?: string;
    description?: string;
    code?: string;
    forType?: string;
    _id?: string;
    isActive?: boolean;
    visible?: boolean;
    options?: any[];
    disabled?: boolean;
    updateOn?: 'change' | 'blur' | 'submit';
    initialValue?: any;
    [KEY: string]: any;
}

export interface IB360DateObject {
    // format YYYY-MM-DD
    date: string;
    // format HH:MM:SS
    time: string;
    // format +HH:MM
    GMT: string;
}

export interface IPermissionFromApi {
    role: Role;
    orgModules: IModuleSchema;
    roleDetails?: IPermission;
}

export interface IModuleSchema {
    _id?: any;

    form101: boolean;
    documents: boolean;
    attendance: boolean;
    pension: boolean;
    paySlips: boolean;
    social: boolean;
    chat: boolean;
    timewatch: boolean;
    digitalArchive: boolean;

    pensionClearing: boolean;
    benefits: boolean;
    phoneBook: boolean;
    businessTree: boolean;
    refId?: boolean;
}
export interface IOrganization {
    _id?: any;

    companyNumber: string;
    deduction: string;
    companyName: string;
    phoneNumber: string;
    city: string;
    street: string;
    houseNumber: string;
    img: string;
    modules: IModuleSchema;
    zipCode?: string;
    apartmentNumber: string;
    organizationManager?: string | IEmployee;
    isActive: boolean;
    color: string;
}

export enum EGeneralPensionProductType {
    FULL_PENSION = 'fullPension',
    GENERAL_PENSION = 'generalPension',
    OLD_PENSION = 'oldPension',
    GEMEL = 'gemel',
    AGENT_PLAN = 'menahalim',
    STUDYFUND = 'histalmot',
    UNKNOWN = 'unknown',
}

export interface IEmployeePensionProduct {
    _id: string;
    state: State;
    planType: string;
    depositStartDate: Date | number | string;
    finalApproveDate: Date | number | string;
    pdfLinkAWS: string;
    plan: IPensionPlan;
    settelment?: IPensionSettelment;
    employee: string;
    otzarNumber: string;
    pensionCompanyName: string;
    pensionProduct: string;
    planViewData: Array<{ title: string; data: any[] }>;
}

export interface IFiltersSchema {
    category: any;
    categoryValues: any;
}

export interface IRemarksSchema {
    _id?: any;

    remarkBy: string;
    createdAt: Date;
    remark: string;
}

export interface IPensionSettelment {
    _id?: any;
    filters?: IFiltersSchema[];

    plan: any;
    customFieldCode: string;
    business: any;
    forType: string;
    forIds: any;
    dnh: string;
    dnz: string;
    priority: number;
    settelmentNumber: number;
    settelmentName: string;
    isActive: boolean;
    remarks?: IRemarksSchema[];
    createdAt: Date | number;
}

export interface IPensionFeedback {
    isVisable: boolean;
    isActive: boolean;
    createdAt: Date | number;
    lastChangeAt: Date | number;
    lastChangeBy: string | Employee;

    business: string | IBusiness;
    employee: string | Employee;
    organization: string | IOrganization;

    productType: EGeneralPensionProductType;
    fileType: 'NEGETIVE' | 'POSITIVE';

    dateOfPayment?: Date | number;
    timeOfTransfer?: Date | number;
    paymentForDate: Date | number;
    employerDepositDateToManufacturer: Date | number;
    dateOfRequestTaxRefund: Date | number;
    forcastedAcaUpdateIn: Date | number;
    dateOfDepositToBusinessFromManufacturer: Date | number;

    amountOfPaymentToManufacturer: number;
    employeeSalary: number;
    totalAmountOfPaymentToManufacturer: number;
    totalAmountOfPaymentRecivedByManufacturer: number;
    pizToManufacturer: number;
    pizRecivedByManufacturer: number;
    employeeTagmulimToManufacturer: number;
    employeeTagmulimRecivedByManufacturer: number;
    employerTagmulimToManufacturer: number;
    employerTagmulimRecivedByManufacturer: number;
    employeeAcaToManufacturer: number;
    employeeAcaRecivedByManufacturer: number;
    employerAcaToManufacturer: number;
    employerAcaRecivedByManufacturer: number;
    currentAcaRate: number;
    nextMonthAcaAmount: number;
    nextMonthAcaRateInPrecentage: number;
    employerTotalDepositAmountForKupa: number;
    employerTotalDepositAmountForManufacturerBankNumber: number;
    acaCoverageRate: number;
    forcastedAcaRate: number;
    taxRateDeductionFromDeposit: number;
    requestedTaxRefundAmount: number;

    companyNumber: string;
    companyName: string;
    employeeName: string;
    idNumber: string;
    otzarNumber: string;
    year: string;
    month: string;

    businessNumberInAtid: string;
    kupaNumber: string;
    kupaType: string;
    employeeType: string;
    takbulType: string;
    kupaHP: string;
    kupaName: string;
    policyNumber: string;
    recieptNumber: string;
    recieptKupa: string;
    recieptLine: string;
    vaultName: string;
    lineStatus: string;
    employeeStatus: string;
    closingCode: string;
    transmittedFileName: string;
    feedbackFileName: string;
    fileNumber: string;
    fileActionType: string;
    investmentPlanCode: string;
    isCanceled: string;
    manufacturerPaymentStatus: string;
    status: string;
    error: string;
    errorMessage: string;
    userMessage: string;
    employeDepositApprovelId: string;
    manufacturerBankNumber: string;
    manufacturerBranchNumber: string;
    manufacturerAccountNumber: string;
    acaUpdateReason: string;
    moneyStatus: string;
}

export interface IPermissionDetails {
    businessIds: string[];
    departmentIds: string[];
    factoryIds: string[];
    companyName?: string;
    employee: IEmployee;
    idNumber: string;
    permissionGiver: string;
    permissionType: Role;
    roleName: string;
    roleRef: IRoleFromApi;
    userName: string;
    _id?: string;
}

export interface IOrganizationPermssionData {
    businesses: ISelect[];
    departments: ISelect[];
    factories: ISelect[];
    _id?: string;
}

export interface IEditPermissionData extends Partial<IPermissionDetails> {
    icon: 'edit' | 'close';
    title: string;
    selected: string;
    selectedTooltip?: string;
    roles: IRoleFromApi[];
}

/**
 DEPRECATED 
 */
export interface ITablePickerMenuItem {
    displayName: string;
    iconName: string;
    resource: string | number;
}

export interface NavItem {
    displayName: string;
    iconName: string;
    type?: string;
    // deprecated only supports old versions
    resource?: TableActions | number;
    // new name insted of resource more accurate
    action?: TableActions;
    sendingMethod?: NotificationMehods;
    msgType?: MessageType;
    route?: string;
    children?: NavItem[];
    dialogRef?: ComponentType<unknown>;
}

export interface IB360NavItem {
    icon?: string;
    navlistIcon?: string;
    matIcon?: string;
    backgroundColor?: string;
    label: string;
    route?: Array<string>;
    module?: EEmployeeModule;
    fixed?: boolean;
    // not in use in dashboard cubes - only sidebar and drawer
    onlyNavbars?: boolean;

    badgeValue?: number;
    badgeHidden?: boolean;
}

export interface IRoleFromApi {
    _id?: string;

    business: string | IBusiness;
    organization: string | IOrganization;
    createdAt: Date | number | string;
    createdBy: string | Employee;
    isActive: boolean;
    remarks: any[];
    roleName: string;
    roleNumber: string;

    attendance: EPrivilege;
    attendanceMerged: EPrivilege;
    allowRouteToEmployeeSide: EPrivilege;

    chat: EPrivilege;

    documents: EPrivilege;
    documentsMerged: EPrivilege;

    employeeFile: EPrivilege;
    employees: EPrivilege;

    form101: EPrivilege;

    lockMonth: EPrivilege;
    paySlips: EPrivilege;
    pensions: EPrivilege;
    pension: EPrivilege;
    permissions: EPrivilege;
    phoneBook: EPrivilege;
    processes: EPrivilege;
    settings: EPrivilege;
    social: EPrivilege;
    templates: EPrivilege;
    kits: EPrivilege;
}

export interface IErrorLogToApi {
    error: any;
    module: EModule;
    methodName: string;
    fileName: string;
}

export interface IKitDocFromApi {
    _id: string | null;
    label: string;
    value: string;
    isOptional?: boolean;
}

export interface ISelect {
    label: string;
    value: string;
}

export interface IKitFromApi {
    createdBy: string;
    date: string;
    docs: Array<IKitDocFromApi>;
    employeeId: string;
    employeeImg: string;
    kitName: string;
    kitNumber: string;
    qty: number;
    _id: string;
}

export type BiskitsActionMenu = {
    text: string;
    subMenukey?: string;
    hasSubMenu: boolean;
    subMenu?: Array<BiskitsActionMenu>;
};

export interface ApiResponse {
    error: boolean;
    ok: boolean;
    data: any;
    msg?: string;
    showSnack?: boolean;
}

export interface ITimeWatchRemarks {
    remarkBy: string;
    createdAt: Date;
    remark: string;
}

export interface ITask {
    _id?: string;
    employee: string;
    sender: string;
    business: string;
    userIdNumber: string;
    type: ETaskTypes;
    text: string;
    footNote: string;
    senderName: string;

    icon?: string;
    taskRoute?: string;
    isActive?: boolean;
    new?: boolean;
    status?: State;
    createdAt?: Date;
    viewedAt?: Date;
    data?: { [key: string]: any };
    remaindedAt?: Date;
    remarks?: ITimeWatchRemarks[];
    dueTo?: Date;
    taskColor?: { type: string };
    addToEmployeeCalander?: boolean;
    catagory?: { label: string; value: number };
    appends?: { type: [] };
    daysOpen?: number;
}

export interface ITableData {
    tableType: ETableTypes;
    allData?: boolean;
    month?: string;
    year?: string;
    start?: number;
    skip?: number;
    limit?: number;
    end?: number;
    sortDirection?: -1 | 1;
    sortBy?: string;
    filter?: string;
    [key: string]: string | boolean | number;
}

export interface IF101TableRowData {
    bankDetails: {
        bank: string;
        branch: string;
        bankId?: string;
        accountNumber?: string;
    };
    changes: string[];
    department: string;
    employeeId: string;
    employeeImg: string;
    employeeNumber: string;
    filledAt: string;
    formId: string;
    idNumber: string;
    name: string;
    pdfLinkAWS: string;
    sendAt: string;
    startDate: string;
    status: State;
    taxYear: string;

    // it's same as formId
    _id: string;
}

export interface IForm101ChangesFromApi {
    changes: Array<string>;
    name: string;
    idNumber: string;
    employeeNumber: string;
}

export interface IB360TableMetaData {
    class?: string;
    header?: string;
    headers?: string[];
    field: string;
    fields?: string[];
    type?: string;
    sortable?: boolean;
    footer?: boolean;
    filterable?: boolean;
}

export interface ITableColsData {
    class?: string;
    header?: string;
    field: string;
    type: string;
}

export interface IEmployeesTableDataFromApi {
    departmentName: string;
    departmentNumber: string;
    employeeNumber: string;
    form101: string[];
    idNumber: string;
    kit: string;
    name: string;
    pension: string;
    startDate: string;
    _id: string;
}

export interface IForm101DetailsFromApi {
    employeeId: string;
    state: StatusHeb;
    businessId: string;

    appends: IAppends;
    employeeApproveDate: Date;
    pdfLinkAWS: string;
    form101: string;

    message?: string;
    approvedBy?: string;
    finalApproveDate?: Date;
    _id?: string;
}

export interface IDialogData {
    icon: 'Error' | 'Ok';
    title: string;
    subheading: string;
    info: string;
    btnText: string;
    btnClass: string;
}

export interface CreateOrganizationObject {
    business: {
        deduction: string;
        logo: string;
        address: {
            city: string;
            houseNumber: string;
            apartmentNumber: string;
            street: string;
        };
        phoneNumber: string;
        companyName: string;

        companyNumber: string;
        autoReminder?: 3 | 5 | 7;
        factoryIsActive?: boolean;
        isActive?: boolean;
    };
    employee: {
        idNumber: string;
        firstName: string;
        lastName: string;
        email: string;
        phoneNumber: string;
        startDate: Date;
    };
}

export interface Organization {
    deduction: string;
    logo: string;
    address: {
        city: string;
        houseNumber: string;
        apartmentNumber: string;
        street: string;
    };
    phoneNumber: string;
    companyName: string;

    companyNumber: string;
    autoReminder?: 3 | 5 | 7;
    factoryIsActive?: boolean;
    isActive?: boolean;
    _id: string;
}

export interface AddBusinessForm {
    deduction?: string;
    address?: {
        city?: string;
        houseNumber?: string;
        apartmentNumber?: string;
        street?: string;
    };
    phoneNumber?: string;
    companyName?: string;
    companyNumber?: string;
    wageDetails?: {
        wageSoftware?: string;
        closeWageDate?: string;
        paymentMethodToInsuranceCompany?: string;
        institutionCode?: string;
        providerCode?: string;
    };
    bankDetails?: IBankDetails;
    autoReminder?: 3 | 5 | 7;
    factoryIsActive?: boolean;
    logo?: string;
    isActive?: boolean;
}

export interface Employee2 {
    idNumber: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    address?: {
        city: string;
        apartmentNumber: string;
        street: string;
        houseNumber: string;
        zipCode: string;
    };

    image?: string;

    landLine?: string;
}

export interface BusinessEmployee extends Employee2 {
    departmentId?: string;
    factoryId?: string;
    startDate: Date;
    birthDate?: Date;
    secondEmail?: string;
    sendSMS?: boolean;
    sendMAIL?: boolean;
    position?: string;
    employeeNumber: string;
    prefixName?: string;
    whatToSend: boolean | string;
    bankDetails?: {
        bankId: string;
        accountNumber: string;
    };
    _id?: string;
}

export interface EmployeeTofes101Array {
    birthDate: Date;
    business: {};
    businesses: any[];
    departmentId: string;
    email: string;
    employeeOrientation: string;
    firstName: string;
    form101State: [];
    idNumber: string;
    lastName: string;
    permissions: [];
    phoneNumber: string;
    role: string;
    startDate: Date;
    yearTax: string;
    _id: string;
}
export interface IGuideCardFeedbacksFromApi {
    viewedByEmployee: any;
    viewedAt: Date;
    commentAsText: string;
    commentAsBoolean: boolean;
}
export interface IGuideCardActionBtnsFromApi {
    btnType: EBtnsType;
    btnAction: EActionBtnsType;
    navigateTo: string;
    btnText: string;
    btnClass?: string;
    icon?: string;
    type?: string;
    text?: string;
    iconPos?: string;
}

export enum EActionBtnsType {
    APPROVE = 'approve',
    BACK = 'back',
}

export enum EBtnsType {
    NO_BACKGROUND_BTN = 'mat-button',
    RAISED_BTN = 'mat-raised-button',
    JUST_STROKE_BTN = 'mat-stroked-button',
    FLAT_BTN = 'mat-flat-button',
    ICON_BTN = 'mat-icon-button',
    FAB = 'mat-fab',
    MINI_FAB = 'mat-mini-fab',
}
export enum EGuideCardType {
    BUSINESS_CARD = 'business_card',
    BIRTHDAY_CARD = 'birthday_card',
    FORM101_CARD = 'form101',
    PENSION_CARD = 'pension',
    PAYSLIP_CARD = 'payslip',
    WELCOME_BACK_CARD = 'welcome_back',
    PROCESS_DONE_CARD = 'process_done',
    DOCUMENT_CARD = 'document',
}

export interface IGuideCardFromApi {
    animationName: string;
    heading: string;
    bodyText: string;
    business: any;
    type: EGuideCardType;
    isActive: boolean;
    feedbacks: IGuideCardFeedbacksFromApi;
    actionBtns: IGuideCardActionBtnsFromApi[];
    createdAt: Date;
    validUntil: Date;
    class: string | string[];
}

export type IBusinessToApi = IBusiness & { _id: string };

export interface IBusiness {
    address: {
        city: string;
        street: string;
        houseNumber: string;
        zipCode: string;
    };
    autoReminder: number;
    chatRooms: [{ _id: string; roomManager: string; name: string }];
    companyName: string;
    companyNumber: string;
    deduction: string;
    defaultPensionPlan?: IBusinessDefaultPensionPlanFromApi;
    factoryIsActive: boolean;
    forms: string[];
    isActive: boolean;
    phoneNumber: string;
    wageDetails: any;
    bankDetails: IBankDetails;
    organization: string;
    businessImg?: string;
    label?: any;
    logo: string;
    color: string;
    customFields?: Array<{
        _id: string;
        field: string;
        value: any;
        optionRef?: any;
        createdAt: string;
        updatedAt: string;
    }>;
    defaultBiz?: boolean;
    forceEmpProfilePicture?: boolean;

    forceEmpEmailAndPhoneNumber?: boolean;
    forceBankDetails?: boolean;
    allowIDontHaveABankAccountStatement?: boolean;

    _id: string;
}

export interface IEmployeeBankDetailsToApi {
    bankStatement: File | string;
    accountNumber: string;

    bankSearch: Partial<IBankDetails>;
}
export interface IBankDetails {
    _id: string;
    bankName: string;
    bankCode: string;
    branchName: string;
    branchNumber: string;
    accountNumber: string;
    state: State;
    createdAt: string;

    approvedAt?: string;
    lastManagerActionBy?: string | IEmployee;
    bankStatement?: string; // link aws
    fixMsg?: string;
}

export interface ILeanEmployee {
    _id: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    color: string;
    country: string;
    department: string;
    displayField: string;
    employeeNumber: string;
    employeePermission: IEmployeePermission;
    employeeState: EEmployeeState;
    idNumber: string;
    manager: string;
    position: string;
    profileImg: string;
}

export interface IChangesStamp {
    at?: Date | number | string;
    by: string | IEmployee | IAgent;
    comment?: any;
}

export interface ITrackChanges {
    creation?: IChangesStamp;
    changes?: IChangesStamp[];
}

export interface IAddress {
    city: string;
    street: string;
    houseNumber: string;
    apartmentNumber?: string;
    zipCode?: string;
    poBox?: string;
}

export interface IEmployee {
    address: IAddress;
    departmentText?: string;
    managerText?: {
        firstName: string;
        lastName: string;
        employeeNumber: string;
    };
    affectByDepartmentOrientation: boolean;
    birthDate: Date | string;
    landLine: string;
    business: IBusiness;
    bankDetails: IBankDetails;
    department: { _id: string; name: string };
    email: string;
    employeeOrientation: [];
    image: string;
    factory: { _id: string; name: string };
    lastForm101State: string;
    firstName: string;
    gender: string;
    maritalStatus: string;
    color: string;
    idType: EIDType;
    country: string;
    depositStatus: string;
    displayField?: string;
    employeeState: EEmployeeState;
    employeeType: EEmployeeType;
    empOpenTasks?: number;
    form101States: [
        {
            yearTax: string;
            state: string;
            formId?: string;
            taxYear?: string;
            sendAt: string;
            message?: string;
        },
    ];
    idNumber: string;
    lastName: string;
    position: string;
    prefix: string;
    employeeNumber: string;
    prefixName: string;
    lastSeen: string;
    permissions?: any[];
    bank?: any;
    phoneNumber: string;
    role: Role;
    manager: string;
    startDate: string | Date;
    employmentEndDate: string | Date;
    isActive: boolean;
    yearTax: string;
    __v: number;
    _id: string;
    profileImg?: string;
    welcomeText?: string;
    firstTimer?: boolean;
    customFields?: { [key: string]: any };
    onboarding?: IEmployeeOnboardingFromApi;
    customFieldsV2?: ISelectedCustomField[];
    employeePermissions: IEmployeePermission;
    form101Data: {
        state: State;
        val: string;
        readonly _id: string;
    };
    pensionData: Array<{
        _id: State;
        count: number;
    }>;
    payslipConcent: boolean;
    payslipConcentSignature?: string;
    payslipConcentAt?: string;
    organization: string;

    // from form101
    children?: IF101ChildrenFromAPI[];
    spouseDetails?: IF101SpouseS1;
    idAppends?: IB360Attachment[];
    israeliResident?: EBinaryChoice;
    kibbutzOrSettlementMember?: EF101Kibbutz;
    healthCareProvider?: EF101HealthCareProvider;
    hasChildren?: boolean;
    dateOfAliya?: IB360DateObject;
    name?: string;
}

export interface IAddressDocument {
    address: string;
    cityCode: number;
    cityName: string;
    streetCode: number;
    streetName: string;
}

export interface Employee {
    address: {
        city: string;
        street: string;
        houseNumber: string;
        apartmentNumber: string;
        zipCode: string;
        poBox: string;
    };
    affectByDepartmentOrientation: boolean;
    birthDate: Date | string;
    landLine: string;
    business: IBusiness;
    bankDetails: IBankDetails;
    department: { _id: string; name: string };
    email: string;
    employeeOrientation: [];
    image: string;
    factory: { _id: string; name: string };
    lastForm101State: string;
    firstName: string;
    gender: string;
    maritalStatus: string;
    color: string;
    idType: string;
    country: string;
    depositStatus: string;
    form101States: [
        {
            yearTax: string;
            state: string;
            formId?: string;
            taxYear?: string;
            sendAt: string;
            message?: string;
        },
    ];
    idNumber: string;
    lastName: string;
    position: string;
    prefix: string;
    employeeNumber: number;
    prefixName: string;
    lastSeen: string;
    permissions?: any[];
    bank?: any;
    phoneNumber: string;
    role: Role;
    startDate: string | Date;
    employmentEndDate: string | Date;
    isActive: boolean;
    yearTax: string;
    __v: number;
    _id: string;
    profileImg?: string;
    welcomeText?: string;
    firstTimer?: boolean;
    customFields?: { [key: string]: any };
    customFieldsV2?: Array<{ [key: string]: any }>;
    onboarding?: IEmployeeOnboardingFromApi;
    employeePermissions: IEmployeePermission;
    darga?: Array<{
        _id: string;
        idNumber: string;
        business: string;
        employeeNumber: string;
        sum: string;
        before: string;
        now: string;
        approved: boolean;
    }>;
}

export interface IEmployeePermission {
    timewatch: boolean;
}

export interface IEmployeeOnboardingFromApi {
    business: string;
    createdAt: Date;
    docs: IEmployeeProcessDocFromApi[];
    employee: string;
    isActive: boolean;
    process: string;
    processName: string;
    processType: string;
    status: State;
    _id: string;
}

export interface IEmployeeProcessDocFromApi {
    name: string;
    type: EEmployeeProcess;
    document?: string;
    form101?: string;
    payslip?: string;
    pension?: string;
}

export interface EmployeesArrayToApi {
    departmentNumber: number;
    employees: [
        {
            firstName: string;
            lastName: string;
            birthDate: string;
            startDate: string;
            email?: string;
            phoneNumber?: string;
            factory?: string;
        },
    ];
}

export interface INotification {
    sms: boolean;
    email: boolean;
    employeesId: string[];
    messageType: MessageType;
    message?: string;
    taxYearInvitation?: string;
}

export interface IBirthDaysFromApi {
    _id: string;
    color: string;
    name: string;
    imgUrl: string | null;
    birthDate: string;
    age: number;
}
export interface IDashboardSumsFromApi {
    employees: {
        _id: string;
        joinedThisMonth: number;
        inActiveEmps: number;
        activeEmpsL: number;
    }[];
    f101: {
        employeePending: number;
        employerPending: number;
        resubmit: number;
        _id: string;
    }[];
}

export interface IBasicChartDataFromApi {
    _id: string;
    sum: number;
}

export interface INotificationFromApi {
    creationDate: Date;
    businessId: boolean;
    isTouched: boolean;
    isNewNotif: boolean;
    messageContent: string;
    notifType: MessageType;
    recevierId: string;
    recevierIdNumber: string;
    senderId: string;
    senderIdNumber: string;
    icon: string;
    _id: string;
}

export interface IDashboardNotificationFromApi {
    docs: [
        {
            name: string;
            date: Date;
        },
    ];
    _id: string;
}

export interface IAppends {
    sugHacnacaFile1?: string;
    sugHacnacaFile2?: string;
    sugHacnacaFile3?: string;
    teudaFile?: string;
    academicDegreeFile?: string;
    alimonyFile?: string;
    blindFile?: string;
    blindExtraFile?: string;
    diasbiltyKidFile?: string;
    employeeIdTypeBio1Image?: string;
    employeeIdTypeBio2Image?: string;
    employeeIdTypeRegular1Image?: string;
    noIncomeFile?: string;
    oleFile?: string;
    pkidShumaFile?: string;
    psakDinFile?: string;
    seperateFile?: string;
    spouseFile?: string;
    yeshuvFile?: string;
    [key: string]: string;
}

export interface UserProfile {
    mainNav?: string;
    employee: IEmployee;
    user: IUser;
    permission: IPermissionFromApi;
    glHash: string;
}

export interface IUser {
    _id?: any;
    idNumber: string;
    firstName: string;
    lastName: string;
    birthDate?: Date;
    email?: string;
    phoneNumber?: string;
    defaultLanguage?: ELanguage;
    primaryOrgnization?: any;
    primaryBusiness?: any;
    profileImg?: {
        type: string;
        value: string;
    };
    address?: IAddress;
    firstTimer?: boolean;
    lastSeen?: any;
    gender?: string;
    role?: Role;
    highestRole?: Role;
    defaultBusiness?: string;
}
export interface IDepartment {
    businessId?: string;
    employeeOrientation?: string[];
    employees?: string[];
    factoryId?: string;
    factoryNumber?: string;
    name?: string;
    number?: string;
    _id?: string;
    id?: string;
}

export interface ISelectedCustomField {
    field: any;
    value?: any;
    code?: string;
    optionRef?: any;
    createdAt?: Date | number;
    updatedAt?: Date | number;
}

export interface IEmployeeProcessDocument {
    _id?: string;
    department: string | IDepartment;
    factory: string | IFactory;
    organization: string | IOrganization;
    isOptional: boolean;
    name: string;
    type: EEmployeeProcess;
    guideCard?: any;
    document?: any;
    form101?: any;
    pension?: any;
    payslip?: any;
}

export interface Deserializable {
    deserialize(input: any): this;
}

export interface IEmployeeProcess {
    _id?: any;
    department: string | IDepartment;
    factory: string | IFactory;
    organization: string | IOrganization;
    business: string | IBusiness;
    employee: string | Employee;
    process: any;
    processName: string;
    processType: EProcessType;
    docs: IEmployeeProcessDocument[];
    isActive: boolean;
    createdAt: Date | number;
    status?: State;
}

export type QueryResult<T> = T[];
export interface IBank {
    _id: string;
    bankName: string;
    bankCode: string;
    branchName: string;
    branchNumber: string;
    bankSearch?: string;
}

export interface ISpouseDetails {
    _id?: string;
    idNumber: string;
    firstName: string;
    lastName: string;
    birthDate: Date | number | string;
    alliyaDate: Date | number | string;
}

export interface Departments {
    businessId?: string;
    employeeOrientation?: string[];
    employees?: string[];
    factoryId?: string;
    name?: string;
    number?: string;
    _id?: string;
    id?: string;
}

export interface IFactory {
    businessId: string;
    name: string;
    number: number;
    _id?: any;
}

export interface IDepartmentsTableRow {
    _id?: any;
    department: string;
    departmentName: string;
    departmentNumber: string;
    employees: number;
    factory: string;
    factoryId: string;
    factoryName: string;
    factoryNumber: string;
}

export interface FeedBacks {
    EmployeeBenefitsToManufacturerPercent: string;
    actionType: string;
    businessNumber: string;
    clientNumber: string;
    closingCode: string;
    compensationReceivedPercent: string;
    compensationToManufacturerPercent: string;
    dateDepositToManufacturer: Date;
    depositStatus: string;
    depositValueDate: Date;
    digitalSafe: string;
    employeeBenefitsReceivedPercent: string;
    employeeName: string;
    employerBenefitsReceivedPercent: string;
    employerBenefitsToManufacturerPercent: string;
    employerHP: string;
    employerName: string;
    feedbackFileName: string;
    fileName: string;
    fundsReferenceNumber: string;
    investmentPlan: string;
    isCanceled: string;
    kupa: string;
    kupaNumber: string;
    line: string;
    manufacturerHP: string;
    manufacturerName: string;
    manufacturerPaymentStatus: string;
    paymentMonth: Date;
    paymentType: string;
    policy: string;
    predictedProvisionAmount: string;
    receipt: string;
    reportedSalary: string;
    salaryMonth: Date;
    salaryToManufacturer: string;
    sendingDate: Date;
    status: string;
    totalDepositToManufacturer: number;
    totalEmployerDepositToKupa: number;
    totalReceived: number;
    totalSentToManufacturer: number;
    year: string;
}

export interface UserOrganizationTree {
    name: string;
    _id: string;
    businesses: Array<{
        name: string;
        _id: string;
        factories: Array<{
            name: string;
            _id: string;
            departments: Array<{
                name: string;
                number: string;
                businessId: string;
                factoryId: string;
                _id: string;
            }>;
        }>;
    }>;
}

export interface IB360TimewatchProjectForm {
    _id: any;
    general: IGeneralForm;
    locations: ILoactionsForm;
    reminders: IRemaindersForm;
    overtime: IOvertimeForm;
}

export interface IOvertimeForm {
    breakDeduction: FormGroup<IB360CheckboxWithSelectControls>;
    breakDeductionRestriction: FormGroup<IB360CheckboxWithSelectControls>;
    overtimeCalc: FormArray<
        FormGroup<{
            numberOfHours: FormControl<number>;
            rateInPercent: FormControl<number>;
        }>
    >;
}

export interface IGeneralForm {
    projectName: FormControl<string>;
    projectNumber: FormControl<string>;
    audience: FormControl<{
        audienceType: TLabelValue;
        selectedAudience: any[];
    }>;
    audienceFilters: FormArray<FormGroup<any>>;
}

export interface ILoactionsForm {
    isLocationRequired: FormControl<boolean>;
    allowedLocations: FormArray<FormGroup<ICurrentLocationForm>>;
}

export interface ICurrentLocationForm {
    description: FormControl<string>;
    geoFenceInMeters: FormControl<number>;
    address: FormControl<string>;
    lat: FormControl<number>;
    lng: FormControl<number>;
    icon: FormControl<string>;
    photo: FormControl<string>;
}

export type IRemaindersForm = {
    workDays: FormGroup<{
        sunday: FormControl;
        monday: FormControl;
        tuesday: FormControl;
        wednesday: FormControl;
        thursday: FormControl;
        friday: FormControl;
        saturday: FormControl;
    }>;
    clockInRemainder: FormGroup<IB360CheckboxWithSelectControls>;
    clockOutRemainder: FormGroup<IB360CheckboxWithSelectControls>;
    overtimeLimitRemainder: FormGroup<IB360CheckboxWithSelectControls>;
    autoClockOutAfter: FormGroup<IB360CheckboxWithSelectControls>;
    overtimeCalculationMap: FormControl;
};

export interface PermissionDetails {
    relatedTo: SuprviseOn;
    relatedId: string;
    employees: EPermission;
    documents: EPermission;
    processes: EPermission;
    payStub: EPermission;
    pension: EPermission;
    form101: EPermission;
}

export interface IB360AttendanceDetailFromApi {
    doc: ITimeWatch;
    in: string;
    out: string;
    project: string | null;
    shiftType: EAttendanceOptions;
    recordState: State;

    sumInMs: number;
    date: Date;
    _id?: string;
}

export interface IB360EmployeeAttendanceDetail
    extends IB360AttendanceDetailFromApi {
    department: string;
    project: string | null;
    readOnly: boolean;
    status: string;
}

export interface IB360EmployeeAttendanceFromApi {
    _id: {
        month: number;
        year: number;
    };
    docs: IB360EmployeeAttendanceDetail[];
    totalSumInMs: number;
}

export interface IB360AttendanceFromApi {
    _id: {
        month: number;
        year: number;
    };
    docs: IB360AttendanceDetailFromApi[];
    totalSumInMs: number;
}

export interface IB360CalenderSingleDayTableRow {
    specialDays?: Array<string>;
    isSabbatical?: boolean;
    date: Date;
    [key: string]: any;
}

export interface IB360TimeoffSingleTableRow {
    _id: string;
    employeeId: string;
    name: string;
    department: string;
    employeeNumber: string;
    shiftType: EAttendanceOptions;
    fromDate: Date | number | string;
    endDate: Date | number | string;
    totalDays: number;
    profileImg: string;
    status: State;
    append?: string;
}

export interface IB360TWMonthlySingleTableRow {
    _id: string;
    department: string;
    employeeId: string;
    employeeNumber: string;
    name: string;
    employeeImg: string;
    totalInMs: number;
    totalBreaksInMs: number;
    totalOvertimeInMs: number;
    totalRegularInMs: number;
    totalTimeoffDays: number;
    totalWorkingDays: number;
}

export interface IB360TWDailySingleTableRow {
    _id: string;

    employeeId: string;
    employeeNumber: string;
    name: string;
    date: string;
    employeeImg: string;
    department: string;
    project: string;

    status: ETimeWathcState;

    shiftType?: EAttendanceOptions;

    projectDetails: ITimeWatchProject;

    sumInMs: number;
    breakInMs: number;
    overtimeInMs: number;
    regularInMs: number;

    changed: boolean;

    startShiftDetails?: IShiftDetails;
    endShiftDetails?: IShiftDetails;

    in?: string;
    out?: string;

    approvedBy?: string;
}

export interface IB360CalenderSingleDayCard {
    specialDays?: Array<string>;
    isSabbatical?: boolean;
    date: Date;
    disabled: boolean;

    attendanceDetails: Array<IB360AttendanceDetailFromApi>;
}

export interface IPermission {
    businessId?: string;
    privilege: Array<PermissionDetails>;
    role: Role;
    idNumber?: string;
    employeeId: string;
}

export const MY_FORMATS = {
    parse: {
        dateInput: 'MM/YYYY',
    },
    display: {
        dateInput: 'MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

export interface IMyPermission {
    privilegeSummary: PermissionDetails;
    role: Role;
}

export interface IChat {
    participants: string[];
    subject: string;
    messages: [IChatBody];
    createdAt: Date;
    status: string;
    businessId: string;
    isRead: boolean;
    lastModified: Date;
    _id: string;
}

export interface IChatBody {
    appends: Array<{ _id: string; path: string; value?: any }>;
    msg: string;
    participants: string[];
    sendAt: Date;
    sender: string;
    local: boolean;

    isRead: boolean;
    sendAtString?: string;
    _id?: string;
}

export interface IFlowFromApi {
    businessId: string;
    flow: EFlow[];
    name: string;
    type: string;
    _id: string;
}

export interface IBusinessDefaultPensionPlanFromApi {
    planId: string;
    headingText: string;
    pensionText: string;
    notifyAfterNumOfDays: number;
    autoJoinAfterNumOfDays: number;
    _id: string;
}

export interface IFilters {
    category: string;
    categoryValues: string[];
}

export type TKit = {
    name: string;
    _id?: any;
};

export type TAction = {
    type: string;
    label: string;
    isOptional: boolean;
    value?: TKit;
    _id?: any;
};

export interface IDoneDocumentTableRow {
    _id: string;
    documentName: string;
    documentNumber: string;
    pdfLinkAWS: string;
    createdAt: string;
    isActive: boolean;
    state: State;
    employeeId: string;
}

export interface IPendingDocumentTableRow {
    _id: string;
    // REFS
    employeeId: string;
    business: string;
    department: string;
    factory: string;
    organization: string;
    formTemplate: string;
    document: string;
    kit: string;
    log: string;

    state: State;
    isOptional: boolean;
    pdfLinkAWS: string;

    // string  date
    createdAt: string;
    updatedAt: string;
    approvedAt: string;

    currentFillerIndex: number;
    currentFillers: [{ id: string; name: string; _id: string }];
    totalDocFillers: number;
    isActive: boolean;
    docData?: [IFillerFormData];
    lastUpdateAt?: string;
    lastUpdateBy?: string;
    documentName: string;
    documentNumber: string;
    documentRecipients: Array<{
        filterByField: string;
        value?: string;
        _id: string;
    }>;

    nextSignee: Partial<IEmployee> | string;
    _searchableString: string;
}

export interface IDocumentRecipient {
    filterByField: string;
    value?: string;
    _id: string;
}

export interface IDocumentFromApi {
    _id?: string;
    business: string;
    isOptional?: boolean;
    color: string;
    createdAt: string;
    docName: string;
    docType: 'general';
    employeeId: string;
    employeeImg: string;
    formCreator: string;
    initialDocValues: Array<any>;
    isActive: boolean;
    name: string | null;
    profileImg: string;
    recipients: Array<IDocumentRecipient>;
    template: string;
    docNumber: string;
}

export interface IBusinessProcessBase {
    _id?: string;
    processName: string;
    processNumber: number;
    processType: EProcessType;
    recipients: string[];
    recipientType: ERecipientType;
    priority: number;
    filters?: IFilters[];
    actions: TAction[];
    welcomeText?: string;
}

export interface IBusinessProcess {
    _id?: string;
    business: any;
    createdBy: any;
    createdAt: number | Date;
    isActive: boolean;
    processName: string;
    processNumber: number;
    processType: EProcessType;
    recipients: string[];
    recipientType: ERecipientType;
    priority: number;
    filters?: IFilters[];
    actions: TAction[];
    welcomeText?: string;
}

export interface IProcessSettingsTableRow extends IBusinessProcess {
    flow: IFlowFromApi;
}

interface IDynamicFormFieldLabel {
    lang: EB360Lang;
    value: string;
    defaultLang: boolean;
}

enum EDynamicFormFieldType {
    TEXT,
    NUMBER,
    DATE,
    DATE_RANGE,
    SINGLE_SELECT,
    MULTI_SELECT,
    CHECKBOX,
    RADIO,
    SINGLE_FILE,
    MANY_FILES,
    TEXTAREA,
    TIME,
}

interface IDynamicFormField {
    /**
     * @description label will be translated to all supported languages upon creation
     */
    label: Array<IDynamicFormFieldLabel>;

    /**
     * @description - the type of input (text, number, date, etc.)
     * @type
     */
    type: EDynamicFormFieldType;

    /**
     * @description - options is mandatory for SINGLE_SELECT, MULTI_SELECT, RADIO, and CHECKBOX types
     */
    options?: Array<{ label: string; value: string }>;
}
